body {
  background: rgb(83, 83, 83);
  color: white;
  display: block;
  align-content: center;
}

.banner {
  padding: 20vh;
  width: 100%;
  height: 100vh;
  text-align: center;
  background-image: url('./images/portfoliobanner.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: rgb(228, 218, 192);
  border-bottom: 5px solid white;
}

.banner > img {
  border: 3px solid white;
  border-radius: 70px;
  height: 30vh;
  width: auto;
  margin: 0 auto;
}

.banner > h1 {
  font-family: 'Bebas Neue', cursive;
  font-size: 15vh;
  text-shadow: 3px 3px black;
}

.banner > h2 {
  font-family: 'Bebas Neue', cursive;
  font-size: 5vh;
  text-shadow: 3px 3px black;
}

.navBar {
  width: 100%;
  text-align: center;
  height: 3vh;
}

.navBar > a {
  display: inline-block;
  width: auto;
  font-size: 2vh;
  padding: 0.5vh 1vw;
  text-decoration: 'none';
  color: 'white';
  align-items: 'center';
}

.content {
  width: 50%;
  margin: 5vh auto;
  font-size: 2vh;
}

a, a:link, a:visited {
  color: white;
}

a:hover {
  color: rgb(156, 143, 107);
}

.socials {
  width: 15%;
  margin: auto;
  text-align: center;
}

.socials > a {
  color: white;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .banner {
    padding: 25vw 5vw;
  }

  .banner > h1 {
    font-size: 13vw;
    text-shadow: 3px 3px black;
  }
  
  .banner > h2 {
    font-family: 'Bebas Neue', cursive;
    font-size: 6vw;
    text-shadow: 3px 3px black;
  }

  .banner > img {
    border-radius: 10px;
  }
  
  .socials {
    width: 50%;
  }
  .navBar {
    width: 100%;
    height: 5vh;
  }
  
  .navBar > a {
    padding: 1vh 1vw;
    font-size: 5vw;
  }

  .content {
    width: 95%;
  }
}